import isArray from 'lodash/isArray';

/**
 * Méthode permettant de valider si une valeur est renseignée.
 * Si la valeur est vide, null ou non définie on retourne false
 * et une erreur est ajoutée au tableau des erreurs.
 * Sinon on retourne true.
 *
 * @param value      : la valeur à tester.
 * @param errors     : le tableau des erreurs.
 * @param labelError : le libellé de l'erreur.
 * @param fieldError : le champ en erreur.
 * @returns {boolean}
 */
export const validateEmptyValue = (value, errors, labelError, fieldError) => {
  if (value === null || value === undefined || value === '') {
    if (isArray(errors)) {
      errors.push({ labelError, fieldError });
    }
    return false;
  }
  return true;
};

export const validateEmptyPenalty = (value, errors, labelError, fieldError) => {
  if (value === null || value === undefined || value === '' || value === 'Kind') {
    if (isArray(errors)) {
      errors.push({ labelError, fieldError });
    }
    return false;
  }
  return true;
};
