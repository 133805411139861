/**
 * The higher order component responsible for controlling the uic code and submitting the form.
 */
import React, { Component } from 'react';
import { PropTypes, func } from 'prop-types';
import { validateEmptyValue } from 'services/validators';
import {
  CODE_AGENT_INPUT, PASSWORD_INPUT, DEFAULT_TIMEOUT, DEFAULT_ENDPOINT,
} from 'services/consts';
import { getProperty } from 'utils/config';

export default function hoc(Template) {
  const Steps = class Step1 extends Component {
    constructor(props) {
      super(props);
      this.state = {
        codeagent: '',
        password: '',
        tokenCaptcha: '',
        errors: [],
        codeAgentError: false,
        passwordError: false,
        endpoint: DEFAULT_ENDPOINT,
        cloudflareCaptchaSiteKey: getProperty('site'),
        fetchingCloudflareCaptcha: false,
      };
      const langInit = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'fr';
      props.handleLang(langInit);
      if (
        props.error
      ) {
        this.hasError = true;
      } else {
        this.shouldFetch = true;
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.error !== prevState.errors) {
        return {
          errorWS: nextProps.error,
        };
      }
      return null;
    }

    componentDidUpdate() {
      if (!this.state.fetchingCloudflareCaptcha) {
        const id = 'cloudflare-captcha-script';
        const isScriptExist = document.getElementById(id);

        if (!isScriptExist) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onloadTurnstileCallback';
          script.id = id;
          script.async = true;
          script.defer = true;

          document.body.appendChild(script);
        }
      }
    }

    changeInputCodeAgent = (event) => {
      this.setState({ codeagent: event.target.value });
    };

    changeInputPassword = (event) => {
      this.setState({ password: event.target.value });
    };

    clearErrors = () => {
      if (this.props.error !== null) {
        this.props.clearError();
      }
    };

    validate = () => {
      // On nettoie les potentielles erreurs.
      this.clearErrors();
      this.setState({ errorWS: null });
      const errors = [];
      let codeAgentError = false;
      let passwordError = false;

      // Si le champ code agent n'est pas renseigné.
      validateEmptyValue(
        this.state.codeagent,
        errors,
        'ctr.error.codeagent.empty',
        CODE_AGENT_INPUT,
      );

      // Si le champ password n'est pas renseigné.
      validateEmptyValue(
        this.state.password,
        errors,
        'ctr.error.password.empty',
        PASSWORD_INPUT,
      );
      if (errors.length > 0) {
        errors.forEach((e) => {
          if (e.fieldError === CODE_AGENT_INPUT) {
            codeAgentError = true;
          }
          if (e.fieldError === PASSWORD_INPUT) {
            passwordError = true;
          }
          return (codeAgentError, passwordError);
        });
        this.setState({ errors, codeAgentError, passwordError });
        return false;
      }
      // Si des erreurs ont été levées on propage l'info.
      this.setState({ errors, codeAgentError, passwordError });
      return true;
    };

    validation = () => {
      if (this.validate()) {
        this.props.setCodeAgent(this.state.codeagent);
        this.props.setPassword(this.state.password);
        if (this.props.fetch) {
          this.props.fetch(
            this.state.codeagent,
            this.state.password,
            this.state.tokenCaptcha,
            this.state.endpoint,
            DEFAULT_TIMEOUT,
          );
        }
      }
    };

    onSubmitCF = (token) => {
      this.setState({
        tokenCaptcha: token,
      }, () => {
        this.validation();
      });
    };

    onloadTurnstileCallback = (event) => {
      event.preventDefault();
      window.turnstile.render('#cf-turnstile-check', {
        sitekey: this.state.cloudflareCaptchaSiteKey,
        callback: this.onSubmitCF,
      });
    };

    render() {
      const styleInit = sessionStorage.getItem('style') ? sessionStorage.getItem('style') : 'default';
      this.props.handleStyle(styleInit);
      return (
        <Template
          {...this.props}
          onSubmit={this.onloadTurnstileCallback}
          changeInputCodeAgent={this.changeInputCodeAgent}
          changeInputPassword={this.changeInputPassword}
          intl={this.props.intl}
          state={this.state}
          onCkeckAmount={this.onCkeckAmount}
          goBack={this.goBack}
        />
      );
    }
  };

  Steps.propTypes = {
    intl: PropTypes.shape({ formatMessage: func }).isRequired,
    error: PropTypes.shape(),
    clearError: PropTypes.func.isRequired,
    setCodeAgent: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    handleLang: PropTypes.func.isRequired,
    handleStyle: PropTypes.func.isRequired,
  };

  Steps.defaultProps = {
    error: {},
  };

  return Steps;
}
