import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import HeaderBlock from 'components/HeaderBlock/HeaderBlock';
import { validateEmptyValue } from 'services/validators';

const ConnexionInterne = ({
  intl, state, changeInputCodeAgent, changeInputPassword,
  onSubmit, lang, handleLang, handleStyle,
}) => (
  <>
    <Header
      lang={lang}
      handleLang={handleLang}
      handleStyle={handleStyle}
      calledFromLoginPage
    />
    <main role="main" id="main-content" className="main-content">
      <section className="container" style={{ marginTop: '70px' }}>
        <div className="main-div">
          <HeaderBlock intl={intl} step={0} />
          <form onSubmit={onSubmit}>
            {validateEmptyValue(state.errorWS)
              && (
              <div
                id="form-error-alert-block"
                className="form-error-block-txt"
                role="alert"
                aria-live="assertive"
                tabIndex="-1"
              >
                <FormattedMessage id="ctr.search_error">
                  {(text) => (<p className="form-error-block-title">{text}</p>)}
                </FormattedMessage>
                <ul className="form-error-block-list">
                  {validateEmptyValue(state.errorWS)
                    && <li key={state.errorWS.code}>{state.errorWS.message}</li>}
                </ul>
              </div>
              )}
            <div className="fieldset clearfix">
              <p className="required-fields">
                <sup className="asterisk-required">*</sup>
                <span>{intl.formatMessage({ id: 'step.info.mandatory_field' })}</span>
              </p>
              <span className="fieldset">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="ctr-input-block clearfix">
                      <label
                        htmlFor="train-number"
                        className="train-search-label form-label-text"
                      >
                        {intl.formatMessage({ id: 'connexion.input.label.name' })}
                        <sup className="asterisk-required">&nbsp;*</sup>
                      </label>
                      <div className="clearfix">
                        <div className={`ctr-form-field-wrapper form-field-wrapper-icon-clear form-field-wrapper form-field-wrapper-tooltip ${state.codeAgentError && 'form-error'}`}>
                          <input
                            type="text"
                            name="code-agent-input"
                            id="code-agent-input"
                            className="form-field-with-info form-field ctr-input-agent-login"
                            placeholder={intl.formatMessage({ id: 'example' })}
                            title={intl.formatMessage({ id: 'connexion.input.label.name' })}
                            value={state.codeagent}
                            onChange={changeInputCodeAgent}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="ctr-input-block clearfix">
                      <label
                        htmlFor="train-number"
                        className="train-search-label form-label-text"
                      >
                        {intl.formatMessage({ id: 'connexion.input.label.password' })}
                        <sup className="asterisk-required">&nbsp;*</sup>
                      </label>
                      <div className={`ctr-form-field-wrapper form-field-wrapper-icon-clear form-field-wrapper form-field-wrapper-tooltip ${state.passwordError && 'form-error'}`}>
                        <input
                          type="password"
                          name="num-ctr-input"
                          id="num-ctr-input"
                          className="form-field-with-info form-field"
                          placeholder={intl.formatMessage({ id: 'example' })}
                          title={intl.formatMessage({ id: 'connexion.input.label.password' })}
                          value={state.password}
                          onChange={changeInputPassword}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </span>
              <div className="btn-wrapper">
                <div className="btn btn-block btn-ctr-back" />
                <button type="submit" className="btn btn-default train-input-btn btn-ctr-next" title={intl.formatMessage({ id: 'connexion' })}>
                  {intl.formatMessage({ id: 'connexion' })}
                </button>
              </div>
              <div id="cf-turnstile-check" className="cf-turnstile" />
            </div>
          </form>
        </div>
      </section>
    </main>
    <Footer lang={lang} handleLang={handleLang} intl={intl} />
  </>
);

ConnexionInterne.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  state: PropTypes.shape().isRequired,
  changeInputCodeAgent: PropTypes.func.isRequired,
  changeInputPassword: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
};

ConnexionInterne.defaultProps = {};

export default injectIntl(ConnexionInterne);
