import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import ibulleNom from 'assets/images/ibulle_nom.jpg';
import ibulleNumero from 'assets/images/ibulle_numero.jpg';
import Tooltip, { TooltipButton, TooltipContent } from 'components/Tooltip/Tooltip';
import { validateEmptyValue } from 'services/validators';
// import { Link } from 'react-router-dom';

const formatErrorMessage = (intl, errorJSON) => {
  let message = null;
  if (errorJSON.status === 500) {
    message = intl.formatMessage({
      id: 'ctr.error.generic',
    });
    return message;
  }
  // Gestion de l'abandon
  if (errorJSON.state.toLowerCase() === 'abandon') {
    // get type of penalty, applicable to CTR
    // since we can have PV or DPL
    const idType = errorJSON.penaltyType === 'PV' ? 'ctr.error.payment.pv.abandon' : 'ctr.error.payment.dpl.abandon';
    message = intl.formatMessage({ id: idType });
    // Gestion de l'annulation par carte expirant avant fin paiement recurrent
  } else if (errorJSON.state.toLowerCase() === 'cancel_cb_exp') {
    message = intl.formatMessage({ id: 'ctr.error.recurring.cb.expiration' });
  } else {
    const idType = errorJSON.penaltyType === 'PV' ? 'ctr.error.payment.pv' : 'ctr.error.payment.dpl';
    message = intl.formatMessage({ id: idType });
  }
  return message;
};

const Step1 = ({
  intl, onSubmit, state, changeInputPenalty, changeInputName, changeInputNumero,
}) => {
  // get agent / client session expired information from session storage
  const agentSessionExpired = sessionStorage.getItem('token.agent.expired');
  // client error message is saved in string format
  const clientSessionExpired = sessionStorage.getItem('token.client.expired');

  // get errorPayment from session storage
  const errorPayment = sessionStorage.getItem('errorPayment');
  let errorJSON = null;
  if (errorPayment !== null) {
    // parse JSON from errorPayment
    errorJSON = JSON.parse(errorPayment);
  }

  // className to change list style and hide list title
  // when error is from partner
  let noPuces = '';
  let noTitle = '';
  if (validateEmptyValue(state.errorWS)
    && (state.errorWS.isPartnerError || (state.errorWS.code === 503 && state.errorWS.label === 'ctr.error.unavailable'))) {
    noPuces += 'no-puces';
    noTitle += 'no-title';
  }

  return (
    <>
      <h2 className="subtitle-step-name-num">{intl.formatMessage({ id: 'step1.main.subtitle' })}</h2>
      <form onSubmit={onSubmit}>
        {/* check if there's any session errors */}
        {/* agent session error */}
        {agentSessionExpired !== null
          && (
            <div
              id="form-error-alert-block"
              className="form-error-block-txt"
              role="alert"
              aria-live="assertive"
              tabIndex="-1"
            >
              {
                agentSessionExpired
              }
            </div>
          )}
        {/* client session error */}
        {clientSessionExpired !== null
          && (
            <div
              id="form-error-alert-block"
              className="form-error-block-txt"
              role="alert"
              aria-live="assertive"
              tabIndex="-1"
            >
              {
                clientSessionExpired
              }
            </div>
          )}
        {/* check if there's an error during payment */}
        {errorPayment !== null
          && (
            <div
              id="form-error-alert-block"
              className="form-error-block-txt"
              role="alert"
              aria-live="assertive"
              tabIndex="-1"
            >
              {
                formatErrorMessage(intl, errorJSON)
              }
            </div>
          )}
        {(state.errors.length > 0
          || (validateEmptyValue(state.errorWS)))
          && (
            <div
              id="form-error-alert-block"
              className="form-error-block-txt"
              role="alert"
              aria-live="assertive"
              tabIndex="-1"
            >
              <FormattedMessage id="ctr.search_error">
                {(text) => (<p className={`form-error-block-title ${noTitle}`}>{text}</p>)}
              </FormattedMessage>
              <ul className={`form-error-block-list ${noPuces}`}>
                {validateEmptyValue(state.errorWS)
                  && (
                    <li key={state.errorWS.code}>
                      <FormattedMessage id={state.errorWS.label} />
                    </li>
                  )}
                {state.errors.map((e) => (
                  <li key={e.index}><FormattedMessage id={e.labelError} /></li>
                ))}
              </ul>
            </div>
          )}
        <div className="fieldset clearfix">
          <p className="required-fields">
            <sup className="asterisk-required">*</sup>
            <span>{intl.formatMessage({ id: 'step.info.mandatory_field' })}</span>
          </p>
          <span className="fieldset">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label className="form-label-text traffic-info-filter-label" htmlFor="select-penalty-type">
                  {intl.formatMessage({ id: 'step1.input.label.kind' })}
                  <sup className="asterisk-required">&nbsp;*</sup>
                </label>
                <div
                  className={`form-select-wrapper ${state.penaltyError && 'form-error'}`}
                >
                  <span className="snc-icon-arrow" aria-hidden="true" />
                  <select
                    id="select-penalty-type"
                    className="form-field"
                    onChange={changeInputPenalty}
                    value={sessionStorage.getItem('inputTypeValue')}
                  >
                    <option
                      value="Kind"
                      key="0"
                    >
                      {intl.formatMessage({ id: 'step1.input.placeholder.kind' })}
                    </option>
                    <option
                      value="PV"
                      key="1"
                      selected={state.penaltyType === 'PV'}
                    >
                      {intl.formatMessage({ id: 'step1.input.value.kind.PV' })}
                    </option>
                    <option
                      value="DPL"
                      key="2"
                      selected={state.penaltyType === 'DPL'}
                    >
                      {intl.formatMessage({ id: 'step1.input.value.kind.DPL' })}
                    </option>
                  </select>
                </div>
                {state.penaltyError
                  && <p className="form-label-error-txt" id="penalty-ctr-input-error">{intl.formatMessage({ id: 'ctr.error.penalty.empty' })}</p>}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="ctr-input-block clearfix">
                  <label
                    htmlFor="name-input"
                    className="train-search-label form-label-text"
                  >
                    {intl.formatMessage({ id: 'step1.input.label.name' })}
                    <sup className="asterisk-required">&nbsp;*</sup>
                  </label>
                  <div className="clearfix">
                    <div
                      className={`ctr-form-field-wrapper form-field-wrapper-icon-clear form-field-wrapper form-field-wrapper-tooltip ${state.nameError && 'form-error'}`}
                    >
                      <input
                        type="text"
                        name="name-input"
                        id="name-input"
                        className="form-field-with-info form-field"
                        placeholder={intl.formatMessage({ id: 'example' })}
                        title={intl.formatMessage({ id: 'step1.input.label.name' })}
                        value={state.name}
                        onChange={changeInputName}
                        /* eslint-disable-next-line jsx-a11y/no-autofocus */
                        autoFocus
                      />
                      <button type="button" className="btn-tooltip" aria-expanded="true" aria-controls="train-num-tooltip">
                        <span className="snc-icon-circle-tooltip" aria-hidden="true" />
                      </button>
                      <Tooltip id="train-num-tooltip" intl={intl}>
                        <TooltipButton>
                          <span
                            className="snc-icon-circle-tooltip"
                            aria-hidden="true"
                          />
                        </TooltipButton>
                        <TooltipContent>
                          <img src={ibulleNom} alt="" />
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    {state.nameError
                      && <p className="form-label-error-txt" id="num-ctr-input-error">{intl.formatMessage({ id: 'ctr.error.name.empty' })}</p>}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="ctr-input-block clearfix">
                  <label
                    htmlFor="num-ctr-input"
                    className="train-search-label form-label-text"
                  >
                    {state.penaltyType === 'DPL' ? intl.formatMessage({ id: 'step1.input.label.num.DPL' }) : intl.formatMessage({ id: 'step1.input.label.num.PV' })}
                    <sup className="asterisk-required">&nbsp;*</sup>
                  </label>
                  <div
                    className={`ctr-form-field-wrapper form-field-wrapper-icon-clear form-field-wrapper form-field-wrapper-tooltip ${state.numeroError && 'form-error'}`}
                  >
                    <input
                      type="text"
                      name="num-ctr-input"
                      id="num-ctr-input"
                      className="form-field-with-info form-field"
                      placeholder={intl.formatMessage({ id: 'example' })}
                      title={intl.formatMessage({ id: 'step1.input.label.num' })}
                      value={state.numero}
                      onChange={changeInputNumero}
                    />
                    <button type="button" className="btn-tooltip" aria-expanded="true" aria-controls="train-num-tooltip">
                      <span className="snc-icon-circle-tooltip" aria-hidden="true" />
                    </button>
                    <Tooltip id="train-num-tooltip" intl={intl}>
                      <TooltipButton>
                        <span
                          className="snc-icon-circle-tooltip"
                          aria-hidden="true"
                        />
                      </TooltipButton>
                      <TooltipContent>
                        <img src={ibulleNumero} alt="" />
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  {state.numeroError
                    && <p className="form-label-error-txt" id="num-ctr-input-error">{intl.formatMessage({ id: 'ctr.error.numero.empty' })}</p>}
                </div>
              </div>
            </div>
          </span>
          <div className="btn-wrapper">
            <div className="btn btn-block btn-ctr-back" />
            <button type="submit" className="btn btn-default train-input-btn btn-ctr-next" title="Rechercher ce numéro de train" disabled={state.isSearchLoading}>
              {intl.formatMessage({ id: 'step.button.next' })}
            </button>
          </div>
        </div>
      </form>

    </>
  );
};

Step1.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  state: PropTypes.shape().isRequired,
  changeInputPenalty: PropTypes.func.isRequired,
  changeInputName: PropTypes.func.isRequired,
  changeInputNumero: PropTypes.func.isRequired,
};

export default injectIntl(Step1);
