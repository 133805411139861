import RestClient from './RestClient';

let restClient = null;

function retrieveDueAmount(
  penalty = '',
  name = '',
  numero = null,
) {
  const entity = {
    name,
    penaltyId: numero,
    penaltyType: penalty,
  };
  let authorization = null;
  if (sessionStorage.getItem('token.agent')) {
    authorization = sessionStorage.getItem('token.agent');
  }
  return restClient.create('/penalty', entity, authorization)
    .then(({ res, head }) => ({
      res: res.penaltyInformationsList,
      msgError: res.error,
      authorization: head.authorization,
    }));
}

function retrieveDueAmountWithToken(token) {
  const entity = { token };
  let authorization = null;
  if (sessionStorage.getItem('token.agent')) {
    authorization = sessionStorage.getItem('token.agent');
  }

  return restClient.create(
    '/penalty',
    entity,
    authorization,
  )
    .then(
      ({ res, head }) => ({
        res: res.penaltyInformationsList,
        msgError: res.error,
        authorization: head.authorization,
      }),
    );
}

function checkoutDueAmount(
  email,
  isPaymentPage,
  lang,
  tickets = [],
) {
  let entity;
  if (email === '') {
    entity = {
      penaltyInformationsList: tickets,
    };
  } else {
    entity = {
      penaltyInformationsList: tickets,
      email,
      isPaymentPage,
    };
  }
  const authorization = sessionStorage.getItem('token.client');
  return restClient.create('/penalty/checkout', entity, authorization, lang)
    .then(({ res, url }) => ({
      res: res.redirect_url,
      msgError: res.error,
      url,
      ivrId: res.ivrId,
    }));
}

function paymentCheck(
  authorization,
  lang,
) {
  const entity = {};
  return restClient.create('/penalty/verify?lang='.concat(lang), entity, authorization)
    .then(({ res, url }) => ({
      res,
      msgError: res.error,
      url,
    }));
}

function connexionAgent(
  codeAgent = '',
  password = null,
  tokenCaptcha = null,
) {
  const entity = {
    login: codeAgent,
    password,
    token: tokenCaptcha,
  };
  // remove expired session message
  sessionStorage.removeItem('token.agent.expired');
  sessionStorage.removeItem('token.client.expired');
  return restClient.create('/agent/login', entity)
    .then(({ head, url }) => ({
      head,
      url,
    }));
}

/*
 * attempt refresh agent token when we get
 * a 401 from the server
*/
function refreshAgentToken(storeIn) {
  const entity = {};
  const authorization = sessionStorage.getItem(storeIn);
  return restClient.create('/agent/login/refresh', entity, authorization)
    .then(({ head }) => ({
      head,
    }));
}

function ws(endpoint, timeout) {
  restClient = new RestClient(endpoint, timeout);
  return {
    retrieveDueAmount,
    retrieveDueAmountWithToken,
    checkoutDueAmount,
    paymentCheck,
    connexionAgent,
    refreshAgentToken,
  };
}

export default (endpoint, timeout) => ws(endpoint, timeout);
